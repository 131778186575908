.ceta-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.ceta-program-box
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin: 20px 0px;
    row-gap: 30px;
}

.ceta-program-box h1
{
    color: #006666;
    text-align: center;
}
.ceta-program-box p
{
    width: 70%;
    color: #006666;
    text-align: center;
    font-size: 22px;
}
.ceta-program-box iframe
{
    width: 400px;
    height: 230px;
    border-radius: 0.2rem;
}
.ceta-program-box span
{
    width: 70%;
    text-align: center;
}

.ceta-program-box img
{
    padding: 10px 0px;
    width: 80%;
    height: auto;
}

.program-btn
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.program-btn button
{
    border: none;
    padding: 10px 20px;
    border-radius: 0.2rem;
}

.courses-container,.program-images
{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
    place-items: center;
    gap: 80px;
    width: 85%;
    padding: 10px;
}

.program-images img
{
    object-fit: cover;
    width: 100%;
    height: 400px;
    border-radius: 1rem;

}

.course-card {
    width: 300px;
    height: 250px;
    position: relative;
    overflow: hidden;
    text-align:left;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    background-size: cover;
    background-position: center;
    transition: transform 0.4s ease-in-out;
  }
  
  .course-card:hover {
    transform: scale(1.05);
  }
  
  .course-card-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 20px;
    border-radius: 10px;
    animation: slideIn 1s;
  }
  #video-desc
  {
    color: #006666;
  }
  
  /* Keyframes for text animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media screen and (max-width:1090px){
    .course-card {
        width: 280px;
        height: 220px;
      }
  }

  @media screen and (max-width:470px) {
    .ceta-program-box iframe
    {
    width: 280px;
    height: 230px;
    } 

    .course-card {
        width: 250px;
        height: 200px;
      }

      .course-card-content h2 
      {
        font-size: 15px;
      }
    
  }
.about-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}

.about-box
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding:10px;
    height: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2),
  0 10px 10px rgba(26, 188, 156, 0.2); 
    border-radius: 8px;
    text-align: center;
}

.about-box h1
{
    color: #006666;
}

.team-member
{
    display: flex;
    justify-content:space-around;
    align-items: center;
    flex-wrap: wrap;
    width:100%;
    gap: 15px;
}

.profile-card
{
    border: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    border-radius: 8px;
    width: 300px;
}

.profile-card h3
{
    margin: 0;
    color: #006666;
}
.profile-card h4
{
    color: #3498db;
}

.profile-card img
{
    padding: 10px;
    width: 220px;
    height: 230px;
    border-radius: 8px;
}

.social-icon
{
    margin-top: 10px;
}

@media  screen and (max-width:350px) {
    .team-member
   {
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  }
}

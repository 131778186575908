.holder-container
{
  margin: 20px 30px;

}


.greeting
{
    width: 100%;
    text-align: center;
    border-bottom: 2px dashed #34495e;
    color: #006666;
}


.getBtn
{
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.getBtn>button
{
    padding: 15px 20px;
    border-radius: 0.2rem;
    border: none;
    outline: none;
    cursor: pointer;
}
.getBtn>button:hover
{
    background-color: #FFF;
}


/* Container for the card grid */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 10px;
  gap: 10px;
  padding: 2rem;
  justify-items: center;
}
  
  /* Individual card styling */
  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease-in-out;
    border: 1px solid #17445E;
  }
  
  .card:hover {
    transform: translateY(-5px); /* Slight hover effect */
  }

  .card-header
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding-bottom: 20px;
  }
  
  h3 {
    color: #006666;
  }

  .card-header-btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .card-header-btn>button{
    padding: 7px 12px;
    background-color: #1abc9c;
    color:white;
    border: none ;
    border-radius: 0.2rem;
    cursor: pointer;

  }
  .card-header-btn>button:hover{
    background-color: white;
    color:black;
    border: 1px solid #34495e;
  }
  
  p {
    color: #006666;
  }

  .card>a{
    text-align: center;
    color:#17445E;
    text-decoration: none;
    border: 1px solid black;
    padding: 5px 30px;
    border-radius: 0.2rem;
}
.card>a:hover{
    color: #ff8000;
}

.issuer-dropdown {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 250px;
}

.issuer-dropdown:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 3px #007bff;
}


@media screen and (max-width:800px) {
  .getBtn
{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    margin-top: 20px;
}

}

@media screen and (max-width:500px) {


  .getBtn
  {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      text-align: center;
      margin-top: 20px;
  }
  .holder-doc
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }

 
}

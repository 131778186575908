.media-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    margin-bottom: 10px;
    
}

.media-container h1
{
    color: #006666;
    font-size: 40px;
}

.media-image-box
{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
    place-items: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2),
  0 10px 10px rgba(26, 188, 156, 0.2); 
    padding: 15px;
    gap: 20px;
    width: 90%;
    border-radius: 8px;
}

.media-image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    padding: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2),
  0 10px 10px rgba(26, 188, 156, 0.2); 
    border-radius: 8px;

}

.media-image img{
    width: 180px;
    height: 110px;

}

.media-image a
{
    padding: 10px 30px;
    text-decoration: none;
    background-color: #006666;
    color: white;
    border-radius: 8px;
}
.media-image a::after
{
    content: " ➶";
}



